import { defineStore } from "pinia";
import axios from "@/services/axios";
import Echo from "laravel-echo";
import { bus } from "@/bus";
import { play } from "@/helpers";

export const useStore = defineStore("store", {
  state: () => ({
    token: window.localStorage.getItem("token"),
    user: null,
    employer: null,
    company: null,
    unread: false,
    taskTypes: [],
    roles: [],
    groups: [],
    online: [],
    funnels: [],
    fields: [],
    employers: [],
    levels: [],
    stages: [],
    dialogsQueue: 0,
  }),
  getters: {
    auth() {
      return Boolean(this.user);
    },
    fieldsByModel() {
      return (model) => this.fields.filter((field) => field.model == model);
    },
    fieldsOrder() {
      return this.fields.filter(({ model }) => model == "orders");
    },
    fieldsClient() {
      return this.fields.filter(({ model }) => model == "clients");
    },
    fieldsEmployer() {
      return this.fields.filter(({ model }) => model == "employers");
    },
    fieldsNumberOrder() {
      return this.fields.filter(
        ({ model, type }) =>
          model == "orders" && (type == "number" || type == "money")
      );
    },
    modulesMap() {
      return (
        this.employer?.modules
          ?.sort((a, b) => a.sort - b.sort)
          .reduce((acc, item) => {
            if (!item.level) {
              return acc;
            }
            acc[item.id] = item;
            return acc;
          }, {}) || {}
      );
    },
    can() {
      return (id, level = 1) => !id || this.modulesMap[id]?.level >= level;
    },
  },
  actions: {
    async logout() {
      try {
        if (this.auth) {
          await axios.post("logout");
        }
      } catch (e) {
        //
      } finally {
        localStorage.removeItem("token");
        this.token = null;
        this.user = null;
        this.employer = null;
      }
    },
    async loadCompany() {
      try {
        const { data } = await axios.get("company/data");
        this.unread = data.unread;
        this.funnels = data.funnels;
        this.integrations = data.integrations;
        this.taskTypes = data.taskTypes;
        this.groups = data.groups;
        this.roles = data.roles;
        this.fields = data.fields;
        this.levels = data.levels;
        this.stages = data.stages;
        this.employer = data.employer;
        this.employers = data.employers;
        window.Echo.join(`company.${this.company.id}`)
          .here((items) => (this.online = items))
          .joining((item) => this.online.push(item))
          .leaving(
            (item) =>
              (this.online = this.online.filter(({ id }) => item.id != id))
          )
          .listen("CompanyDataEvent", ({ target, event, data }) => {
            this[event](target, data);
          })
          .listen("CompanyEvent", ({ event, data }) => {
            console.log(event, data);

            if (this.employer?.id == data?.employer_id) {
              this.unread = true;
              if (this.user.ui_settings.sounds) {
                play("notification");
              }
            }
            bus.emit(event, data);
          })
          .listen("EmployerEvent", ({ data }) => {
            if (data.id === this.employer.id) {
              this.employer = data;
            }
          });
      } catch (e) {
        //
      }
    },
    async tgLogin() {
      if (!window.Telegram) {
        return;
      }
      try {
        const { data } = await axios.post("tg_login", window.Telegram.WebApp);
        this.token = data;
      } catch (e) {
        //
      }
    },
    async loadData() {
      try {
        const { data: user } = await axios.get("me");
        this.user = user;
        this.companies = user.companies;
        this.company = user.companies.find(({ id }) => id === user.company_id);
        window.Echo = new Echo({
          broadcaster: "reverb",
          key: process.env.VUE_APP_WS_KEY,
          wsHost: process.env.VUE_APP_WS_URL,
          wsPort: process.env.VUE_APP_WS_PORT,
          forceTLS: process.env.VUE_APP_WS_SCHEME == "https",
          disableStats: true,
          enabledTransports: ["ws", "wss"],
          bearerToken: this.token,
          authEndpoint: process.env.VUE_APP_WS_AUTH,
          auth: {
            headers: {
              Accept: "application/json",
            },
          },
        });
        if (this.company && !user.lock) {
          await this.loadCompany();
        }
      } catch (e) {
        this.logout();
      }
    },
    async toggleNotification(key) {
      try {
        const { data } = await axios.post(`toggleNotification/${key}`);
        this.user.notification_settings[key] = data;
      } catch (e) {
        //
      }
    },
    async toggleUi(key) {
      try {
        const { data } = await axios.post(`toggleUi/${key}`);
        this.user.ui_settings[key] = data;
      } catch (e) {
        //
      }
    },
    checkVisibleRules(data) {
      if (!this.employer) {
        return false;
      }
      const { group_id, id } = data.employer || {};
      return (
        this.employer.super ||
        id === this.employer.id ||
        this.employer.groups.filter((item) => item.id === group_id).length > 0
      );
    },
    setToken(data) {
      this.token = data;
      localStorage.setItem("token", data);
    },

    add(target, data) {
      if (!this[target].filter(({ id }) => id == data.id).length) {
        this[target].push(data);
      }
    },
    update(target, data) {
      const index = this[target].findIndex(({ id }) => id == data.id);
      if (index > -1) {
        this[target].splice(index, 1, data);
      } else {
        this[target].push(data);
      }
    },
    destroy(target, data) {
      this[target] = this[target].filter(({ id }) => id != data);
    },

    changeCompanyActive(id, value) {
      const index = this.companies.findIndex((item) => id == item.id);
      if (index < 0) {
        return;
      }
      const company = this.companies[index];
      company.active = value;
      this.companies.splice(index, 1, company);
      return company;
    },
  },
});
