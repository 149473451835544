import script from "./notificationsDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./notificationsDialog.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QToolbar,QToolbarTitle,QBtn,QInnerLoading,QCard,QCardSection,QItem,QItemSection});
