<template>
  <q-dialog
    v-model="isOpen"
    persistent
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="full-height" style="width: 400px">
      <q-toolbar class="bg-positive text-white q-mb-sm">
        <span class="text-subtitle">Карточка клиента</span>
        <q-space></q-space>
        <q-btn
          dense
          flat
          icon="block"
          :color="client.blocked ? 'blue' : 'negative'"
          @click="toggleBlock"
        >
          <q-tooltip v-if="client.blocked">Разблокировать клиента</q-tooltip>
          <q-tooltip v-else>Заблокировать клиента</q-tooltip>
        </q-btn>
        <q-btn
          dense
          flat
          icon="delete"
          color="negative"
          @click="destroy"
          v-if="store.can('clients', 2)"
        ></q-btn>
        <q-btn dense flat icon="close" @click="isOpen = false"></q-btn>
      </q-toolbar>
      <q-tabs
        no-caps
        dense
        class="q-mb-md"
        v-model="tab"
        indicator-color="positive"
      >
        <q-tab name="info" label="Информация"></q-tab>
        <q-tab name="orders" label="Сделки"></q-tab>
      </q-tabs>
      <q-tab-panels
        v-model="tab"
        transition-prev="fade-in-left"
        transition-next="fade-in-left"
      >
        <q-tab-panel name="info" class="q-gutter-y-sm">
          <q-input
            v-model="client.name"
            dense
            standout
            label="Имя/Название клиента"
            @blur="save"
          ></q-input>
          <ClientContacts
            :items="client.contacts"
            :id="client.id"
            v-if="client.contacts"
          ></ClientContacts>
          <div class="flex justify-between items-center">
            <div>Дополнительные поля</div>
            <q-btn
              flat
              noCaps
              color="positive"
              icon="settings"
              size="sm"
              @click="$bus.emit('fields/showDialog', 'clients')"
              v-if="store.can('clients', 3)"
            ></q-btn>
          </div>
          <Field
            :field="field"
            :modelId="client.id"
            v-model="fieldValues[field.id]"
            v-for="field in store.fieldsClient"
            :key="field.id"
          />
        </q-tab-panel>
        <q-tab-panel name="orders" class="q-gutter-y-sm">
          <q-btn
            dense
            flat
            no-caps
            icon="add"
            label="Добавить сделку"
            color="primary"
            @click="onOrdersCreate"
          ></q-btn>
          <OrderCard
            flat
            bordered
            @click="$bus.emit('orders/showDialog', order.id)"
            v-for="order in client.orders"
            :key="order.id"
            :item="order"
            show-actions
            show-activity
            show-employer
          ></OrderCard>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-dialog>
</template>

<script setup>
import OrderCard from "@/views/orders/card.vue";
import Field from "@/components/fields/field.vue";
import ClientContacts from "@/components/contactsList.vue";
import { axios } from "@/services";
import { ref, inject, onMounted, onBeforeUnmount } from "vue";
import { confirm } from "@/helpers";
import { useStore } from "@/store";

const tab = ref("info");
const isOpen = ref(false);
const client = ref({});
const fieldValues = ref({});
const store = useStore();
const $bus = inject("bus");

const loadClient = async (id) => {
  if (!id) {
    return;
  }

  tab.value = "info";
  try {
    const { data } = await axios.get(`clients/${id}`);
    fieldValues.value = data.fvs.reduce((acc, item) => {
      acc[item.field_id] = item[item.type];
      return acc;
    }, {});
    client.value = data;
    isOpen.value = true;
  } catch (e) {
    //
  }
};

const destroy = async () => {
  const isConfirm = await confirm();
  if (!isConfirm) {
    return;
  }

  try {
    await axios.delete(`clients/${client.value.id}`);
    isOpen.value = false;
  } catch (e) {
    //
  }
};

const save = async () => {
  try {
    await axios.put(`clients/${client.value.id}`, {
      name: client.value.name,
    });
  } catch (e) {
    //
  }
};

const toggleBlock = async () => {
  try {
    await axios.post(`clients/${client.value.id}/toggleBlock`);
    client.value.blocked = !client.value.blocked;
  } catch (e) {
    //
  }
};

const onOrdersCreate = () => {
  $bus.emit(`orders/createDialog`, false, client.value.id);
  isOpen.value = false;
};

onMounted(() => {
  $bus.on("clients/showDialog", loadClient);
});

onBeforeUnmount(() => {
  $bus.off("clients/showDialog", loadClient);
});
</script>
