<template>
  <q-btn
    dense
    flat
    noCaps
    :icon="props.icon || 'attach_file'"
    @click.stop.prevent="$bus.emit('upload', props.src)"
    style="z-index: 10000"
  >
    <q-tooltip>Прикрепить файлы</q-tooltip>
  </q-btn>
</template>

<script setup>
import { defineProps, inject } from "vue";

const props = defineProps({
  src: String,
  icon: String,
});
const $bus = inject("bus");
</script>
