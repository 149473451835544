<template>
  <q-img :src="icon"></q-img>
</template>

<script setup>
import { FileIcons } from "@/static";
import { defineProps, ref, onMounted } from "vue";

const props = defineProps({
  ext: String,
});
const icon = ref(FileIcons.unknown);
onMounted(() => {
  let type = "unknown";
  const ext = props.ext.toLowerCase();
  if (["jpg", "jpeg", "gif", "bmp", "png", "svg"].indexOf(ext) > -1) {
    type = "image";
  } else if (["doc", "docx", "pages", "ods"].indexOf(ext) > -1) {
    type = "doc";
  } else if (["pdf"].indexOf(ext) > -1) {
    type = "pdf";
  } else if (["txt"].indexOf(ext) > -1) {
    type = "text";
  } else if (["js", "php", "h", "json", "html"].indexOf(ext) > -1) {
    type = "code";
  } else if (["zip", "rar", "tar", "gz"].indexOf(ext) > -1) {
    type = "zip";
  } else if (["xls", "xlsx", "numbers", "xml", "csv"].indexOf(ext) > -1) {
    type = "xls";
  } else if (["ppt"].indexOf(ext) > -1) {
    type = "ppt";
  } else if (
    ["mp3", "wav", "wma", "ogg", "oga", "mogg", "m4p", "m4a"].indexOf(ext) > -1
  ) {
    type = "audio";
  } else if (
    ["avi", "wmv", "mp4", "mpg", "mpeg", "3gp", "webm", "mkv", "flv"].indexOf(
      ext,
    ) > -1
  ) {
    type = "video";
  }
  icon.value = FileIcons[type];
});
</script>
