<template>
  <q-card class="q-pb-0 copyable full-width" flat>
    <q-toolbar v-if="toolbar">
      <q-toolbar-title>{{ title || "Сообщения" }}</q-toolbar-title>
      <span class="q-gutter-x-sm">
        <q-badge
          color="primary"
          class="cursor-pointer"
          :outline="filter != 'messages'"
          @click="onFilter('messages')"
          label="Сообщения"
        >
          <q-tooltip>Показать только сообщения</q-tooltip>
        </q-badge>
        <q-badge
          color="primary"
          :outline="filter != 'files'"
          class="cursor-pointer"
          @click="onFilter('files')"
          label="Файлы"
        >
          <q-tooltip>Показать только файлы</q-tooltip>
        </q-badge>
        <q-badge
          color="primary"
          :outline="Boolean(filter)"
          class="cursor-pointer"
          @click="onFilter()"
          label="Все"
        ></q-badge>
      </span>
    </q-toolbar>
    <q-scroll-area
      :horizontal-thumb-style="{ opacity: 0 }"
      :style="{ height: height || 'calc(100vh - 160px)' }"
      class="q-px-xs"
      ref="container"
    >
      <div v-for="(group, groupDate) in groupedItems" :key="groupDate">
        <div class="text-center q-mb-sm">
          <q-badge color="primary" outline>{{ groupDate }}</q-badge>
        </div>
        <transition-group
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated fadeOut"
        >
          <q-item
            v-for="item in group"
            dense
            :key="item.id"
            class="q-mb-sm rounded-borders"
            :class="{
              'neon-primary': item.messageable_id && item.system,
            }"
          >
            <q-item-section>
              <q-item-label class="flex justify-between items-center">
                <div class="q-gutter-x-sm">
                  <small>
                    {{ date.formatDate(item.created_at, "HH:mm") }}
                  </small>
                  <span class="text-weight-bolder">{{ item.title }}</span>
                </div>
                <div class="text-caption">
                  <q-btn
                    dense
                    flat
                    round
                    size="sm"
                    icon="close"
                    color="negative"
                    @click="destroyMessage(item.id)"
                    v-if="item.employer_id == store.employer.id && !item.system"
                  ></q-btn>
                </div>
              </q-item-label>
              <q-item-label>
                <div
                  class="wrap isolate-css text-grey"
                  v-html="item.text"
                ></div>
              </q-item-label>
              <q-item-label v-if="item.files">
                <div v-for="file in item.files" :key="file.id">
                  <FileIcon
                    :file="file"
                    withIcon
                    @destroy="
                      item.files = item.files.filter((f) => f.id != file.id)
                    "
                  ></FileIcon>
                </div>
              </q-item-label>
            </q-item-section>
          </q-item>
        </transition-group>
      </div>
    </q-scroll-area>
    <div class="q-px-sm">
      <q-input
        standout
        autogrow
        type="textarea"
        label="Введите сообщение"
        v-model="text"
        @keypress.enter="send"
        class="full-width"
        style="max-height: 200px; overflow: auto"
      >
        <template v-slot:prepend>
          <Uploader :src="`messages/${model}/${id}`"></Uploader>
        </template>
        <template v-slot:append>
          <q-btn
            dense
            icon="send"
            color="primary"
            @click="send"
            :loading="loading"
          ></q-btn>
        </template>
      </q-input>
    </div>
  </q-card>
</template>

<script setup>
import FileIcon from "@/components/fileIcon.vue";
import Uploader from "@/components/uploader.vue";
import { axios } from "@/services";
import { confirm, play } from "@/helpers";
import { date } from "quasar";
import { useStore } from "@/store";
import {
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch,
  computed,
  defineProps,
} from "vue";

const props = defineProps({
  model: String,
  id: String,
  title: String,
  toolbar: Boolean,
  height: String,
});

const { model, title, toolbar, height } = toRefs(props);
const items = ref([]);
const text = ref("");
const loading = ref(false);
const filter = ref("messages");
const $bus = inject("bus");
const store = useStore();

const groupedItems = computed(() =>
  [...items.value]
    .sort((a, b) => {
      const diff = date.getDateDiff(a.created_at, b.created_at, "seconds");
      return diff > 0 ? -1 : 1;
    })
    .reduce((acc, item) => {
      const groupDate = date.formatDate(item.created_at, "DD.MM.YYYY");
      if (!acc[groupDate]) {
        acc[groupDate] = [];
      }
      acc[groupDate].push(item);
      return acc;
    }, {})
);

const onMessageAdd = (data) => {
  const exists = items.value.filter(({ id }) => id == data.id).length;
  // FIXME:
  if (data.messageable_id == props.id && !exists) {
    if (
      !filter.value ||
      (filter.value == "files" && data.files?.length) ||
      (filter.value == "messages" && !data.system)
    ) {
      items.value.unshift(data);
      play("messagein");
    }
  }
};

const onMessageDestroy = (id) => {
  items.value = items.value.filter((item) => item.id !== id);
};

const loadItems = async () => {
  try {
    const params = {
      filter: filter.value,
    };
    const { data } = await axios.get(`messages/${model.value}/${props.id}`, {
      params,
    });
    items.value = data;
  } catch (e) {
    items.value = [];
  }
};

const send = async (e) => {
  if (loading.value || e?.shiftKey || !text.value) {
    return;
  }
  loading.value = true;
  try {
    const { data } = await axios.post(`messages/${model.value}/${props.id}`, {
      text: text.value,
    });
    text.value = "";
    items.value.push(data);
    play("messageout");
  } catch (e) {
    //
  } finally {
    loading.value = false;
  }
};

const destroyMessage = async (id) => {
  if (!(await confirm())) {
    return;
  }
  try {
    await axios.delete(`messages/${id}`);
    items.value = items.value.filter((item) => item.id !== id);
  } catch (e) {
    //
  }
};

const onFilter = (value) => {
  filter.value = value;
  loadItems();
};

onMounted(() => {
  loadItems();
  $bus.on("messages/add", onMessageAdd);
  $bus.on("messages/destroy", onMessageDestroy);
});

onBeforeUnmount(() => {
  $bus.off("messages/add", onMessageAdd);
  $bus.off("messages/destroy", onMessageDestroy);
});

watch(() => props.id, loadItems);
</script>
