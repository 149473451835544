<template>
  <div class="flex q-gutter-x-sm">
    <q-badge
      :outline="!modelValue.includes(status)"
      class="cursor-pointer"
      :color="color(status)"
      v-for="status in statuses"
      :key="status"
      @click="change(status)"
    >
      {{ Statuses[status] }}
    </q-badge>
  </div>
</template>

<script setup>
import { Statuses } from "@/static";
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  statuses: Array,
  modelValue: Array,
});
const emit = defineEmits(["update:modelValue"]);
const change = (status) => {
  const statuses = props.modelValue.slice();
  const index = statuses.indexOf(status);
  if (index > -1) {
    statuses.splice(index, 1);
  } else {
    statuses.push(status);
  }
  emit("update:modelValue", statuses);
};

const color = (status) => {
  return {
    new: "primary",
    work: "amber",
    pause: "grey",
    done: "positive",
  }[status];
};
</script>
