import Dialog from 'quasar/src/plugins/Dialog.js';;
import { useStore } from "@/store";
import axios from "@/services/axios";
import { DEFAULT_ROWS_PER_PAGE } from "./constants";

export const getStorage = (src) => process.env.VUE_APP_STORAGE + src;
export const getApi = (endpoint) => process.env.VUE_APP_API_URL + endpoint;

export const play = (sound) => {
  const src = require(`@/assets/sounds/${sound}.mp3`);
  const audio = new Audio(src);
  audio.play();
  return audio;
};

export const confirm = (text) => {
  const store = useStore();
  if (store.user?.ui_settings.sounds) {
    play("failure");
  }
  return new Promise((resolve) => {
    Dialog.create({
      title: "Внимание!",
      message: text || "Это действие требует подтверждения",
      persistent: true,
      ok: {
        color: "positive",
        label: "Ок",
        noCaps: true,
      },
      cancel: {
        color: "primary",
        noCaps: true,
      },
    })
      .onOk(() => resolve(true))
      .onCancel(() => resolve(false));
  });
};

export const prompt = (text, value = "", ok = "Ок") => {
  return new Promise((resolve) => {
    Dialog.create({
      persistent: true,
      title: text || "Ввод данных",
      prompt: {
        model: value,
        color: "primary",
        standout: true,
        dense: true,
      },
      ok: {
        color: "positive",
        label: ok,
        noCaps: true,
      },
      cancel: {
        color: "primary",
        noCaps: true,
      },
    })
      .onOk((data) => resolve(data))
      .onCancel(() => resolve(false));
  });
};

export const loadPaginatedItems = async (
  route,
  params,
  paginationProps,
  loading,
  pagination
) => {
  const { page, rowsPerPage, sortBy, descending } =
    paginationProps?.pagination || {};
  params.page = page;
  params.sortBy = sortBy;
  params.descending = descending;
  params.rowsPerPage =
    rowsPerPage || Number(localStorage.rowsPerPage) || DEFAULT_ROWS_PER_PAGE;
  loading.value = true;

  try {
    const { data } = await axios.get(route, { params });
    pagination.value.page = data.current_page;
    pagination.value.rowsPerPage = data.per_page;
    pagination.value.rowsNumber = data.total;
    pagination.value.sortBy = sortBy;
    pagination.value.descending = descending;
    localStorage.rowsPerPage = data.per_page;

    return data.data;
  } catch (e) {
    localStorage.rowsPerPage = params.rowsPerPage;
    return [];
  } finally {
    loading.value = false;
  }
};
