<template>
  <q-card class="cursor-pointer overflow-hidden">
    <div class="left-addon flex column">
      <div class="col bg-positive" v-if="isToday"></div>
      <div class="col bg-amber" v-if="!item.tasks_count"></div>
      <div class="col bg-negative" v-if="item.tasks_overdue_count"></div>
    </div>
    <slot></slot>
    <q-item :clickable="!selectable" class="q-px-sm">
      <q-item-section>
        <q-item-label caption class="flex justify-between items-center">
          <small>
            <q-icon
              name="check_circle"
              color="positive"
              size="12px"
              v-if="item.completed_at"
            >
              <q-tooltip class="text-center">
                Сделка завершена <br />
                {{ date.formatDate(item.completed_at, "DD.MM.YYYY HH:mm") }}
              </q-tooltip>
            </q-icon>
            {{ date.formatDate(item.created_at, "DD.MM.YYYY HH:mm") }}
          </small>
          <q-badge
            :color="item.read ? 'positive' : 'negative'"
            :label="`#${item.id}`"
          >
            <q-tooltip v-if="!item.read">Есть новые события</q-tooltip>
          </q-badge>
        </q-item-label>
        <q-item-label v-if="item.name" class="ellipsis">
          {{ item.name }}
        </q-item-label>
        <q-item-label class="flex justify-between items-end">
          <div>
            <div v-if="item.client" class="no-wrap">
              <span>{{ item.client.name }}</span>
            </div>
            <div v-for="field in fields" :key="field.id">
              <cardSlot
                :fv="item.fvs.find((fv) => fv.field_id == field.id)"
                :field="field"
              ></cardSlot>
            </div>
          </div>
          <div class="text-caption text-negative" v-if="diff">
            {{ diff }} дн.
          </div>
        </q-item-label>
        <q-item-label caption v-if="item.employer && showEmployer">
          Ответсвтенный: {{ item.employer?.full_name }}
        </q-item-label>
        <q-item-label caption v-if="item.updated_at && showActivity">
          Последняя активность:
          {{ date.formatDate(item.updated_at, "DD.MM.YYYY HH:mm") }}
        </q-item-label>
        <q-item-label
          caption
          v-if="item.messages?.length && showActions"
          class="ellipsis-3-lines"
        >
          Последнее действие: {{ item.messages[0]?.text }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script setup>
import { date } from "quasar";
import cardSlot from "./cardSlot.vue";
import { computed, toRefs, defineProps } from "vue";

const props = defineProps({
  item: Object,
  fields: {
    type: Array,
    default: () => [],
  },
  showEmployer: {
    type: Boolean,
    default: false,
  },
  showActivity: {
    type: Boolean,
    default: false,
  },
  showActions: {
    type: Boolean,
    default: false,
  },
});
const { item, fields } = toRefs(props);

const diff = computed(() =>
  date.getDateDiff(Date.now(), props.item.updated_at, "days")
);

const isToday = computed(
  () => date.getDateDiff(Date.now(), props.item.created_at, "days") == 0
);
</script>
