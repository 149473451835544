<template>
  <q-select
    dense
    options-dense
    standout
    emit-value
    use-input
    :options="items"
    :use-chips="multiple"
    :multiple="multiple"
    input-debounce="600"
    clearable
    :disable="disable"
    @filter="filter"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    :label="label || 'Объект'"
    option-label="id"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> Записей не найдено </q-item-section>
      </q-item>
    </template>
    <template v-slot:append>
      <q-icon name="search">
        <q-tooltip>
          Поиск по имени клиента, контактам клиента, номеру и названию сделки,
          имени ответственного.
        </q-tooltip>
      </q-icon>
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" class="q-mb-sm">
        <q-item-section>
          <q-item-label caption class="flex justify-between ellipsis">
            <span>
              <span v-if="scope.opt.order_at">Заказ</span>
              <span v-else>Сделка</span>
              #{{ scope.opt.id }}
            </span>
            <span>
              <small v-if="scope.opt.order_at">
                {{ date.formatDate(scope.opt.order_at, "DD.MM.YYYY") }}
              </small>
              <small v-else>
                {{ date.formatDate(scope.opt.created_at, "DD.MM.YYYY") }}
              </small>
            </span>
          </q-item-label>
          <q-item-label v-if="scope.opt.name" class="ellipsis">
            {{ scope.opt.name }}
          </q-item-label>
          <q-item-label v-if="scope.opt.employer" class="ellipsis">
            {{ scope.opt.employer }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup>
import { axios } from "@/services";
import { date } from "quasar";
import { ref, toRefs, defineProps, defineEmits } from "vue";

const props = defineProps({
  modelValue: Object,
  multiple: Boolean,
  label: String,
  disable: {
    type: Boolean,
    default: false,
  },
});
const { modelValue, multiple, label, disable } = toRefs(props);
const emit = defineEmits(["update:modelValue"]);
const items = ref([]);

const filter = (q, update) => {
  update(async () => {
    const { data } = await axios.get("orders/search", { params: { q } });
    items.value = data.data;
  });
};
</script>
