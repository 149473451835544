<template>
  <q-badge :color="color">{{ Statuses[status] }}</q-badge>
</template>

<script setup>
import { Statuses } from "@/static";
import { computed, defineProps } from "vue";

const props = defineProps({
  status: String,
});

const color = computed(
  () =>
    ({
      new: "primary",
      work: "amber",
      done: "positive",
      pause: "grey",
    }[props.status])
);
</script>
