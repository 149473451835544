<template>
  <q-dialog v-model="isOpen" full-height>
    <q-card flat v-if="stage" style="width: 400px">
      <q-toolbar>
        <q-toolbar-title>{{ stage.stage.name }}</q-toolbar-title>
        <q-btn dense flat icon="close" @click="isOpen = false"></q-btn>
      </q-toolbar>
      <MessageList model="stages" :id="stage.id"></MessageList>
    </q-card>
  </q-dialog>
</template>

<script setup>
import MessageList from "@/components/layout/messageList.vue";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";

const isOpen = ref(false);
const stage = ref(null);
const $bus = inject("bus");

const onCallDialog = (data) => {
  stage.value = data;
  isOpen.value = true;
};

onMounted(() => {
  $bus.on("stageMessages/showDialog", onCallDialog);
});

onBeforeUnmount(() => {
  $bus.on("stageMessages/showDialog", onCallDialog);
});
</script>
