<template>
  <q-dialog v-model="isOpen" persistent>
    <q-card style="width: 350px">
      <q-toolbar class="bg-positive text-white">
        <q-toolbar-title>Настройки</q-toolbar-title>
        <q-btn dense flat icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-list>
        <div>
          <q-item
            dense
            v-for="item in notificationSettings"
            :key="item.id"
            tag="label"
          >
            <q-item-section>
              <q-item-label>{{ item.name }}</q-item-label>
            </q-item-section>
            <q-item-section side top>
              <q-toggle
                color="positive"
                :model-value="
                  Boolean(store.user.notification_settings[item.id])
                "
                @update:model-value="store.toggleNotification(item.id)"
              />
            </q-item-section>
          </q-item>
        </div>
        <q-item dense tag="label">
          <q-item-section>
            <q-item-label>Дублировать уведомления в Telegram</q-item-label>
          </q-item-section>
          <q-item-section side top>
            <q-toggle
              color="positive"
              :model-value="store.user.tg"
              @update:model-value="toggleTelegram"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
  <q-dialog v-model="tgDialog">
    <q-card style="width: 500px">
      <q-toolbar class="bg-positive text-white">
        <q-toolbar-title>Подключение Telegram бота</q-toolbar-title>
        <q-btn dense flat round icon="close" v-close-popup></q-btn>
      </q-toolbar>
      <q-card-section>
        <p class="text-weight-bolder">
          Вы можете получать уведомления и управлять своими задачами прямо из
          Telegram. Подключить его можно тремя способами.
        </p>
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bolder">
                Способ 1.
              </q-item-label>
              <q-item-label caption>
                Наведите камеру вашего смартфона на QR – код. У вас откроется
                приложение Telegram.
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="items-center">
              <q-img src="@/assets/tg.png" width="200px"></q-img>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bolder">
                Способ 2.
              </q-item-label>
              <q-item-label caption>
                Если на вашем компьютере установлен Telegram, нажмите на кнопку
                ниже. Бот откроется в приложении.
              </q-item-label>
              <q-item-label>
                <a
                  href="#"
                  class="text-blue text-weight-bolder"
                  @click.prevent="goToBot"
                >
                  Открыть Telegram
                </a>
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bolder">
                Способ 3.
              </q-item-label>
              <q-item-label caption>
                Откройте приложение Telegram на смартфоне, и в поиске найдите
                <span class="text-weight-bolder text-blue"> @RPoint </span>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { axios } from "@/services";
import { useStore } from "@/store";

const store = useStore();
const tgDialog = ref(false);
const isOpen = ref(false);
const $bus = inject("bus");
const notificationSettings = ref([]);

const goToBot = () => {
  window.open(process.env.VUE_APP_TG_URL, "_blank");
};
const toggleTelegram = async () => {
  try {
    const { data } = await axios.post("toggleTg");
    store.user.tg = data.state;
    if (!data.success) {
      tgDialog.value = true;
    }
  } catch (e) {
    //
  }
};

const onCallDialog = async () => {
  try {
    const { data } = await axios.get("notificationSettings");
    notificationSettings.value = data;
  } catch (e) {
    //
  } finally {
    isOpen.value = true;
  }
};

onMounted(() => {
  $bus.on("settings/showDialog", onCallDialog);
});
onBeforeUnmount(() => {
  $bus.off("settings/showDialog", onCallDialog);
});
</script>
