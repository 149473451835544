import script from "./settingsDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./settingsDialog.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QList,QItem,QItemSection,QItemLabel,QToggle,QCardSection,QImg});qInstall(script, 'directives', {ClosePopup});
