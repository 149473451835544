<template>
  <q-card class="q-pb-0 copyable full-width">
    <q-toolbar>
      <div class="flex items-end justify-between full-width">
        <div>
          <div>Задачи</div>
          <div>
            <StatusFilter
              v-model="statuses"
              :statuses="['new', 'work', 'pause', 'done']"
              @update:modelValue="loadItems"
            ></StatusFilter>
          </div>
        </div>
        <slot></slot>
      </div>
    </q-toolbar>
    <q-scroll-area
      :horizontal-thumb-style="{ opacity: 0 }"
      class="q-px-sm h-l2"
      ref="container"
    >
      <q-item
        v-for="item in items"
        :key="item.id"
        class="q-mb-sm rounded-borders neon-positive"
        :class="[`task-priority-${item.priority}`]"
        clickable
        @click="$bus.emit('tasks/showDialog', item.id)"
      >
        <q-item-section>
          <q-item-label class="flex justify-between items-center">
            <div class="q-gutter-x-sm">
              <q-badge color="positive" :label="`#${item.id}`"></q-badge>
              <q-badge
                v-if="item.meeting"
                color="positive"
                :label="`Протокол #${item.meeting.id}`"
                @click.stop.prevent="
                  $bus.emit('meetings/showDialog', item.meeting.id)
                "
              >
                <q-tooltip>Открыть протокол</q-tooltip>
              </q-badge>
              <Status :status="item.status" />
            </div>
            <small
              class="text-caption"
              v-if="item.end"
              :class="{ 'text-negative': item.overdue }"
            >
              {{ date.formatDate(item.end, "DD.MM.YYYY") }}
            </small>
          </q-item-label>
          <q-item-label caption class="flex justify-between items-center">
            <span>
              Создана: {{ item.creator?.full_name || "автоматически" }}
            </span>
            <span class="text-caption text-positive">
              {{ date.formatDate(item.created_at, "DD.MM.YYYY HH:mm") }}
            </span>
          </q-item-label>
          <q-item-label caption>
            Исполнитель: {{ item.employer?.full_name }}
          </q-item-label>
          <q-item-label v-if="item.task_type" caption>
            {{ item.task_type.name }}
          </q-item-label>
          <q-item-label caption v-if="item.text">
            {{ item.text }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-scroll-area>
  </q-card>
</template>

<script setup>
import Status from "@/components/status.vue";
import { axios } from "@/services";
import { date } from "quasar";
import {
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  defineProps,
} from "vue";
import StatusFilter from "../statusFilter.vue";

const props = defineProps({
  link: String,
  id: String,
});
const statuses = ref(["new", "work"]);
const $bus = inject("bus");
const items = ref([]);

const onTasksAdd = (task) => {
  if (task.order_id == props.id || task.meeting_id == props.id) {
    items.value.push(task);
  }
};

const onTasksUpdate = (task) => {
  if (task.order_id == props.id || task.meeting_id == props.id) {
    const index = items.value.findIndex((item) => item.id == task.id);
    if (index > -1) {
      items.value.splice(index, 1, task);
    }
  }
};

const onTasksDestroy = (id) => {
  items.value = items.value.filter((item) => item.id != id);
};

const loadItems = async () => {
  if (!props.link) {
    items.value = [];
    return;
  }
  try {
    const params = {
      statuses: statuses.value,
    };
    const { data } = await axios.get(props.link, { params });
    items.value = data;
  } catch (e) {
    //
  }
};

onMounted(() => {
  loadItems();
  $bus.on("tasks/add", onTasksAdd);
  $bus.on("tasks/update", onTasksUpdate);
  $bus.on("tasks/destroy", onTasksDestroy);
});

onBeforeUnmount(() => {
  $bus.off("tasks/add", onTasksAdd);
  $bus.off("tasks/update", onTasksUpdate);
  $bus.off("tasks/destroy", onTasksDestroy);
});

watch(() => props.link, loadItems);
</script>
