<template>
  <q-inner-loading :showing="loading">
    <q-spinner-dots size="50px" color="primary" />
  </q-inner-loading>
  <q-scroll-area
    :horizontal-thumb-style="{ opacity: 0 }"
    style="height: calc(100vh - 152px)"
    class="q-px-sm"
    ref="timeline"
  >
    <q-list class="q-gutter-y-sm q-mt-sm">
      <q-item
        :class="{
          'neon-primary': active != item.id,
          'neon-negative': active == item.id,
        }"
        v-for="item in items"
        :key="item.id"
        clickable
        @click="change(item)"
      >
        <q-item-section>
          <q-item-label>
            Начал
            <span class="text-weight-bolder">
              {{ item.employer_start }}
            </span>
            в {{ date.formatDate(item.start_at, "DD.MM.YYYY") }}
          </q-item-label>
          <q-item-label>{{ item.text }}</q-item-label>
          <q-item-label v-if="item.done_at">
            Завершил
            <span class="text-weight-bolder">
              {{ item.employer_done }}
            </span>
            в {{ date.formatDate(item.done_at, "DD.MM.YYYY") }}
          </q-item-label>
          <q-item-label caption>
            Продолжительность:
            <span v-if="item.done_at">{{ getTime(item) }}</span>
            <span v-else>Еще не завершено</span>
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-scroll-area>
</template>

<script setup>
import { axios } from "@/services";
import { date } from "quasar";
import { secToTimeFormatter } from "@/formatters";
import { onMounted, ref, defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  id: String,
});
const emit = defineEmits(["change"]);
const items = ref([]);
const active = ref(false);
const loading = ref(false);

const getTime = computed(
  () => (item) =>
    secToTimeFormatter(date.getDateDiff(item.done_at, item.start_at, "seconds"))
);

const loadItems = async () => {
  loading.value = true;
  const { data } = await axios.get(`meetings/${props.id}/results`);
  items.value = data;
  loading.value = false;
};

const change = (item) => {
  active.value = active.value == item.id ? null : item.id;
  emit("change", active.value);
};

onMounted(loadItems);
</script>
