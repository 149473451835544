<template>
  <q-dialog
    v-model="isOpen"
    persistent
    maximized
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-layout view="hHh lpR fFf" container>
      <q-header>
        <q-toolbar>
          <div class="flex items-center">
            <span>Протокол совещания</span>
            <q-badge color="positive" class="q-mx-sm">
              #{{ meeting.id }}
            </q-badge>
            <meetingEmployers :employers="meeting.employers"></meetingEmployers>
          </div>
          <q-space></q-space>
          <q-btn dense flat round icon="close" @click="close"></q-btn>
        </q-toolbar>
      </q-header>
      <q-page-container class="bg-page">
        <q-page class="row q-col-gutter-x-sm q-pt-sm">
          <div class="col-12 col-md-3 flex items-stretch">
            <q-card class="full-width relative-position">
              <q-scroll-area
                :horizontal-thumb-style="{ opacity: 0 }"
                style="height: calc(100vh - 60px)"
              >
                <q-card-section class="q-gutter-y-sm">
                  <q-list dense>
                    <q-item>
                      <q-item-section class="text-weight-bolder">
                        {{ meeting.title }}
                      </q-item-section>
                    </q-item>
                    <q-item v-if="meeting.text">
                      <q-item-section>
                        <q-item-label>Повестка дня</q-item-label>
                        <q-item-label caption>{{ meeting.text }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section> Дата проведения </q-item-section>
                      <q-item-section side>
                        <q-badge
                          color="primary"
                          v-if="meeting.repeat == 'daily'"
                        >
                          Ежедневно в
                          {{ date.formatDate(meeting.date, "HH:mm") }}
                        </q-badge>
                        <q-badge
                          color="primary"
                          v-else-if="meeting.repeat == 'weekly'"
                        >
                          {{ date.formatDate(meeting.date, "dddd") }} в
                          {{ date.formatDate(meeting.date, "HH:mm") }}
                        </q-badge>
                        <q-badge
                          color="primary"
                          v-else-if="meeting.repeat == 'monthly'"
                        >
                          Каждое {{ date.formatDate(meeting.date, "D") }} число
                          в
                          {{ date.formatDate(meeting.date, "HH:mm") }}
                        </q-badge>
                        <q-badge color="primary" v-else>
                          {{ date.formatDate(meeting.date, "DD.MM.YYYY") }}
                        </q-badge>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section> Создано </q-item-section>
                      <q-item-section side>
                        {{ meeting.creator?.full_name }}
                      </q-item-section>
                    </q-item>
                    <q-item v-if="meeting.time">
                      <q-item-section> Длительность </q-item-section>
                      <q-item-section side>
                        {{ meeting.time }} мин
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>Статус</q-item-section>
                      <q-item-section side>
                        <q-badge
                          v-if="meeting.status == 'work'"
                          color="positive"
                        >
                          Началось
                        </q-badge>
                        <q-badge
                          v-else-if="meeting.status == 'done'"
                          color="positive"
                        >
                          Завершено
                        </q-badge>
                        <q-badge
                          v-else-if="meeting.status == 'new'"
                          color="primary"
                        >
                          Новое
                        </q-badge>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section> Задачи </q-item-section>
                      <q-item-section side v-if="meeting.tasks_count">
                        {{ meeting.tasks_done_count }}
                        /
                        {{ meeting.tasks_count }}
                      </q-item-section>
                      <q-item-section side v-else>
                        Не поставлено
                      </q-item-section>
                    </q-item>
                  </q-list>
                  {{ time }}
                  <q-btn
                    disable
                    outline
                    size="xl"
                    :label="secToTimeFormatter(time)"
                    class="full-width"
                  ></q-btn>
                  <q-btn
                    dense
                    no-caps
                    label="Завершить"
                    color="negative"
                    class="full-width"
                    @click="done"
                    v-if="meeting.status == 'work'"
                  >
                  </q-btn>
                  <q-btn
                    dense
                    no-caps
                    label="Начать"
                    color="positive"
                    class="full-width"
                    @click="start"
                    v-else-if="meeting.status == 'new'"
                  ></q-btn>
                </q-card-section>
              </q-scroll-area>
            </q-card>
          </div>
          <div class="col-12 col-md-5 flex items-stretch">
            <q-card class="full-width">
              <q-tabs v-model="tab" no-caps indicator-color="positive">
                <q-tab label="История" name="messages"></q-tab>
                <q-tab label="Результаты" name="results"></q-tab>
              </q-tabs>
              <q-tab-panels v-model="tab">
                <q-tab-panel name="messages" class="q-pa-none">
                  <MessageList
                    model="meetings"
                    toolbar
                    :id="meeting.id"
                    v-if="meeting.id"
                    height="calc(100vh - 206px)"
                  ></MessageList>
                </q-tab-panel>
                <q-tab-panel name="results">
                  <MeetingResults
                    :id="meeting.id"
                    v-if="meeting.id"
                    @change="changeResult"
                  ></MeetingResults>
                </q-tab-panel>
              </q-tab-panels>
            </q-card>
          </div>
          <div class="col-12 col-md-4 flex items-stretch q-gutter-y-sm">
            <TaskList :id="meeting.id" :link="taskLink" v-if="taskLink">
              <q-btn
                dense
                color="positive"
                icon="add"
                @click="
                  $bus.emit('tasks/showDialog', null, {
                    meeting,
                  })
                "
                v-if="meeting.status == 'work'"
              >
                <q-tooltip>Добавить задачу</q-tooltip>
              </q-btn>
            </TaskList>
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </q-dialog>
</template>

<script setup>
import { axios } from "@/services";
import { date, useQuasar } from "quasar";
import MeetingEmployers from "@/views/meetings/employers.vue";
import TaskList from "@/components/layout/taskList.vue";
import MessageList from "@/components/layout/messageList.vue";
import MeetingResults from "@/views/meetings/results.vue";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { confirm } from "@/helpers";
import { secToTimeFormatter } from "@/formatters";

const isOpen = ref(false);
const meeting = ref({});
const time = ref(0);
const tab = ref("messages");
const taskLink = ref("");
const $bus = inject("bus");
const $q = useQuasar();
let interval = null;

const loadMeeting = async (id) => {
  try {
    const { data } = await axios.get(`meetings/${id}`);
    taskLink.value = `meetings/${data.id}/tasks`;
    if (data.start_at) {
      time.value = date.getDateDiff(
        data.done_at ?? new Date(),
        data.start_at,
        "seconds"
      );
    }
    meeting.value = data;
    updateTimer();
    interval = setInterval(updateTimer, 1000);
    isOpen.value = true;
  } catch (e) {
    //
  }
};

const close = () => {
  if (interval) {
    clearInterval(interval);
  }
  isOpen.value = false;
  meeting.value = {};
};

const changeResult = (id) => {
  if (id) {
    taskLink.value = `meetings/${meeting.value.id}/results/${id}`;
  } else {
    taskLink.value = `meetings/${meeting.value.id}/tasks`;
  }
};

const updateTimer = () => {
  if (meeting.value?.status == "work") {
    time.value++;
  }
};

const start = async () => {
  if (!(await confirm())) {
    return false;
  }
  try {
    const { data } = await axios.post(`meetings/${meeting.value.id}/start`);
    meeting.value.status = "work";
    meeting.value.start_at = data;
  } catch (e) {
    //
  }
};

const done = async () => {
  if (
    !(await confirm(
      "Завершить совещание? Дальнейшее редактирование будет невозможно!"
    ))
  ) {
    return false;
  }
  try {
    const { data } = await axios.post(`meetings/${meeting.value.id}/done`);
    meeting.value.status = "done";
    meeting.value.done_at = data;
  } catch (e) {
    //
  }
};

const onMeetingsUpdate = (data) => {
  if (data.id == meeting.value.id) {
    meeting.value = data;
  }
};

const onMeetingsDestroy = (id) => {
  if (id == meeting.value.id) {
    $q.notify("Совещание удалено");
    close();
  }
};

onMounted(async () => {
  $bus.on("meetings/showDialog", loadMeeting);
  $bus.on("meetings/update", onMeetingsUpdate);
  $bus.on("meetings/destroy", onMeetingsDestroy);
});

onBeforeUnmount(() => {
  $bus.off("meetings/showDialog", loadMeeting);
  $bus.off("meetings/update", onMeetingsUpdate);
  $bus.off("meetings/destroy", onMeetingsDestroy);
  clearInterval(interval);
});
</script>
