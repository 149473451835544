<template>
  <q-dialog v-model="isOpen" persistent>
    <q-card style="width: 450px">
      <q-card-section>
        <p class="text-subtitle">Уважаемый {{ store.user.name }}!</p>
        <p>
          Для завершения регистрации, пожалуйста, введите код из письма, которое
          мы отправили на указанный Вами адрес электронной почты
        </p>
        <q-input dense standout v-model="code" label="Код из письма"></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          color="amber"
          noCaps
          label="Выслать код повторно"
          @click="resendCode"
          :disable="isLockButton"
        ></q-btn>
        <q-btn
          color="positive"
          label="Подтвердить"
          noCaps
          @click="verify"
          :disable="!code"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { inject, onBeforeUnmount, onMounted, defineEmits, ref } from "vue";
import { useStore } from "@/store";
import { axios } from "@/services";
import { useQuasar } from "quasar";

const emit = defineEmits(["success"]);
const isOpen = ref(false);
const $bus = inject("bus");
const $q = useQuasar();
const store = useStore();
const code = ref("");
const isLockButton = ref(false);
const onCallDialog = () => {
  isOpen.value = true;
};

const verify = async () => {
  try {
    await axios.post(`verifyEmail/${code.value}`);
    isOpen.value = false;
    emit("success");
  } catch (e) {
    //
  }
};

const resendCode = async () => {
  try {
    await axios.post("sendVerifyCode");
    $q.notify("Код успешно отправлен");
    isLockButton.value = true;
  } catch (e) {
    //
  }
};

onMounted(() => {
  $bus.on("emailVerify", onCallDialog);
});
onBeforeUnmount(() => {
  $bus.off("emailVerify", onCallDialog);
});
</script>
