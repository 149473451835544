import script from "./taskList.vue?vue&type=script&setup=true&lang=js"
export * from "./taskList.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QToolbar,QScrollArea,QItem,QItemSection,QItemLabel,QBadge,QTooltip});
