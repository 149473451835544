export const tailFormatter = (q, values) => {
  if (values?.length !== 3) {
    return "";
  }
  const rem = q % 10;
  if (rem === 1) {
    return values[0];
  } else if (rem > 1 && rem < 5) {
    return values[1];
  }
  return values[2];
};

export const numberFormat = (value, isCurrency = false) => {
  const num = String(value).replace(/[^\d.-]+/g, "");
  const number = new Intl.NumberFormat(
    "ru-RU",
    isCurrency
      ? {
          style: "currency",
          currency: "RUB",
        }
      : {}
  );
  return number.format(num);
};

export const phoneFormatter = (value) => {
  if (!value) {
    return "";
  }
  const part1 = value.slice(0, 3);
  const part2 = value.slice(3, 6);
  const part3 = value.slice(6, 8);
  const part4 = value.slice(-2);
  return `+7 ${part1}-${part2}-${part3}-${part4}`;
};

export const secToTimeFormatter = (value) => {
  if (!value) return "00:00:00";
  value = Math.floor(value);
  const d = Math.floor(value / 86400);
  const h = Math.floor((value % 86400) / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = value % 60;

  const pad = (num) => String(num).padStart(2, "0");

  const prefix = d ? `${d}д ` : "";
  return `${prefix}${pad(h)}:${pad(m)}:${pad(s)}`;
};

export const minToTimeFormatter = (value) => {
  if (!value) return "00:00";
  value = Math.floor(value);
  const d = Math.floor(value / 1440);
  const h = Math.floor((value % 1440) / 60);
  const m = value % 60;

  const pad = (num) => String(num).padStart(2, "0");

  const prefix = d ? `${d}д ` : "";
  return `${prefix}${pad(h)}:${pad(m)}`;
};

export const minToDaysFormatter = (value) => {
  return Math.floor(value / 1440);
};
