<template>
  <div class="text-center q-gutter-y-sm q-mt-xl">
    <div class="error-code">404</div>
    <div class="text-h6">Упс... Страница, которую вы ищите не найдена</div>
    <q-btn
      dense
      no-caps
      color="positive"
      @click="$router.push({ name: 'home' })"
      label="Вернуться на главную"
    />
    <div class="gears q-mt-xl">
      <div class="gear one">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <div class="gear two">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <div class="gear three">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>
